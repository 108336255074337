<template>
  <div v-if="visible" class="popup-overlay1" @touchstart="startDrag" @touchend="stopDrag" @touchmove.prevent="dragPopup">
    <div class="popup-content1" :style="{ top: popupTop + 'px', left: popupLeft + 'px' }">
      <button class="close-button1" @click="closePopup">&times;</button>
      <h2 style="color: black;">เพิ่มข้อมูล</h2>
      <div class="popup-body1 flex1">
        <form @submit.prevent="insertData">
          <div class="form-row1">
          <label class="label1">BU Name : </label>
            <input type="text" class="textboxBu" v-model="BU_NAME" placeholder="ชื่อ BU" required>
          </div>
          <div class="form-row">
            <label class="label1">ชื่อบริษัท :</label>
          <input type="text" class="textboxBu" v-model="COMPANY_NAMETHAI" placeholder="ชื่อบริษัท (ภาษาไทย)" required><br><br>
          </div>
          <div class="form-row">
          <label class="label1">Company Name : </label>
          <input type="text" class="textboxBu" v-model="COMPANY_NAMEENG" placeholder="Company Name" required><br><br>
          </div>
          <div class="form-row">
          <label class="label1">TAX ID : </label>
          <input type="text" class="textboxBu" v-model="TAX_ID" placeholder="TAX ID" required><br><br>
          </div>
          <div class="form-row">
          <label class="label1">Background Image : </label>
          <input type="file" ref="image1" accept="image/*" required @change="handleFileChange($event, 'image1')"><br><br>
          </div>
          <div class="form-row">
            <label class="label1">Logo Company: </label>
          <input type="file" ref="image2" accept="image/*" required @change="handleFileChange($event, 'image2')"><br><br>
          </div>
          <div class="form-row">
          <label class="label1">Background Color : </label>
          <input type="color" v-model="BG_COLOR"><br><br>
          </div>
          <div class="form-row1">
            <button type="submit" class="btn-step-insert">Insert</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';


export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      BU_NAME: '',
      COMPANY_NAMETHAI: '',
      COMPANY_NAMEENG: '',
      TAX_ID: '',
      isDragging: false,
      touchX: 0,
      touchY: 0,
      popupTop: 0,
      popupLeft: 0,
      image1: null,
      image2: null,
      BG_COLOR: '#000000',
    };
  },
  methods: {
    async insertData() {
      const toast = useToast();
      try {
      const formData = new FormData();
      formData.append('BU_NAME', this.BU_NAME);
      formData.append('COMPANY_NAMETHAI', this.COMPANY_NAMETHAI);
      formData.append('COMPANY_NAMEENG', this.COMPANY_NAMEENG);
      formData.append('TAX_ID', this.TAX_ID);
        formData.append('BG', this.image1);
        formData.append('Logo', this.image2);
        formData.append('BG_COLOR', this.BG_COLOR);

        const response = await axios.post(process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT + '/insert', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.message) {
          toast.success(response.data.message);
        }
        this.closePopup();
      } catch (error) {
        console.error('There was an error!', error);
        toast.error('มีข้อผิดพลาดในการส่งข้อมูล');
      }
    },
    startDrag(event) {
      this.isDragging = true;
      this.touchX = event.touches[0].clientX;
      this.touchY = event.touches[0].clientY;
    },
    stopDrag() {
      this.isDragging = false;
    },
    dragPopup(event) {
      if (this.isDragging) {
        const deltaX = event.touches[0].clientX - this.touchX;
        const deltaY = event.touches[0].clientY - this.touchY;
        this.popupTop += deltaY;
        this.popupLeft += deltaX;
        this.touchX = event.touches[0].clientX;
        this.touchY = event.touches[0].clientY;
      }
    },
    closePopup() {
      this.$emit("close");
    },
      handleFileChange(event, key) {
      const file = event.target.files[0];
      if (file) {
        this[key] = file;
      }
    },
    mounted() {
      this.$nextTick(() => {
        const popupBody = this.$refs.popupBody;
        if (popupBody.scrollHeight > popupBody.clientHeight) {
          popupBody.style.overflowY = "scroll";
        }
      });
    },
  },
};
</script>

<style>
.flex1 {
  display: flex;
}
.popup-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.popup-content1 {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  width: auto;
  height: auto;
  text-align: center;
  cursor: grab;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}
.close-button1 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.popup-header1 {
  margin-bottom: 15px;
}
.popup-body1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-row, .form-row1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label1 {
  margin-right: 10px;
  color: black;
}

.textboxBu {
  width: 300px;
  height: 30px;
}

.btn-step-insert {
  margin-top: 10px;
  border-radius: 7px;
  font-size: 18px;
  color: white;
  padding: 15px 20px;
  background-color: rgb(44, 44, 193);
  width: 100%;
  text-align: center;
  border: none;
  cursor: pointer;
}
</style>
