<template>
    <div class="">
        <div class="modal-content">
            <img class="arv-logo" src="black.png" />
            <div v-if="isUploaded" class="button" @click.prevent="generate()">Preview</div>
            <div class="dv-form-data">
                <table>
                    <tr>
                        <td class="table-label">Card Type</td>
                        <td><select v-model="working_type" class="select">
                                <option value="Permanent">Permanent</option>
                                <option value="Temporary">Temporary</option>
                            </select></td>
                    </tr>
                    <tr>
                        <td class="table-label">BU</td>
                        <td><select v-model="bu" class="select" @change="handleSelection">
                                <option v-for="buName in buNames" :key="buName.BU_NAME" :value="buName.BU_NAME">
                                    {{ buName.BU_NAME }}
                                </option>
                            </select></td>
                    </tr>
                    <tr>
                        <td class="table-label">NICKNAME</td>
                        <td><input type="text" v-model="nickname" class="textbox" /></td>
                    </tr>
                    <tr>
                        <td class="table-label">NAME</td>
                        <td><input type="text" v-model="name" class="textbox" /></td>
                    </tr>
                    <tr>
                        <td>SURNAME</td>
                        <td><input type="text" v-model="surname" class="textbox" /></td>
                    </tr>
                    <tr>
                        <td>CARD No.</td>
                        <td><input type="text" v-model="cardNo" class="textbox" /></td>
                    </tr>
                    <tr>
                        <td>AFFILIATION</td>
                        <td><input type="text" v-model="affiliation" class="textbox" /></td>
                    </tr>
                    <tr>
                        <td class="table-label">ADDRESS</td>
                        <td><select v-model="selectedAddress" class="select" @change="handleAddressSelection">
                                <option v-for="address in addresses" :key="address.ID" :value="address.ID">
                                    {{ address.ADDRESS_THAI }}
                                </option>
                            </select></td>
                    </tr>
                    <tr>
                        <td>Image</td>
                        <td>
                            <input type="file" ref="file" id="fileSelected" @change="loadImage($event)" accept="image/*"
                                style="display: none" />
                            <button @click="$refs.file.click()" class=" btn-step-1">Select image</button>
                        </td>
                    </tr>
                    <tr>
                    </tr>
                </table>
            </div>
            <div class="cropper">
                <cropper ref="cropper" class="upload-example-cropper" :src="image.src" @change="OnCropChange"
                    :stencil-props="{
                        aspectRatio: 2 / 3,
                    }" />
            </div>
            <div class="modal">
                <img id="close" :class="getClass()" src="close.png" @click.prevent="close()" class="close">
                <img id="img-blob" :class="getClass()" class="img-blob">
                <img id="img-blob-back" :class="getClass()" class="img-blob-back">
                <img v-if="isLoading" class="img-loading" src="loading.gif">
                <img v-if="isLoading" class="img-loading-preview" src="loading.gif">
                <div v-if="!isUploaded && !isLoading" class="img-loading">No Image</div>
                <!-- <div v-if="!isUploaded && !isLoading" class="img-loading-preview">No Image</div> -->
                <div class="label-adjust">Adjust</div>
                <div class="label-preview">Preview</div>
                <img id="img-test" class="img-before">
                <img id="img-bubg" :src="message" class="img-bg">
                <!-- <img id="img-bu" v-if="isUploaded" :src="logobu" class="img-bu"> -->
                <div class="mock-bottom">
                    Position Preview Only
                </div>
                <div class="info-area">
                    Filetype: jpg or png (Max 3 MB.)<br>
                    Orientation: Portrait<br>
                    Resolution: minimum 662 x 1040 pixels<br>
                </div>
                <div :class="getClass()" class="info-generate">
                    Right-click then 'Save image as.. to download your card'
                </div>
                <img id="img-test-rm" class="img-after">
                <div id="apppopu" class="pop-up">
                    <a href="#" style="text-decoration: underline;" class="popup-link" @click="showPopup">How To Use
                        <font-awesome-icon icon="question-circle" /> </a>
                    <Popup :visible="isPopupVisible" @close="isPopupVisible = false"> </Popup>
                </div>
                <div id="apppopu" class="pop-up-add">
                    <a href="#" style="text-decoration: underline;" class="popup-link-add" @click="showInsertPopup">Add
                        BU information <font-awesome-icon icon="plus-circle" /> </a>
                    <BuInsert :visible="isInsertVisible" @close="isInsertVisible = false"></BuInsert>
                </div>
                <div id="apppopu" class="pop-up-add-address">
                    <a href="#" style="text-decoration: underline;" class="popup-link-address"
                        @click="showInsertAddressPopup">Add Address <font-awesome-icon icon="plus-circle" /> </a>
                    <Address :visible="isAddressVisible"
  @close="isAddressVisible = false"
  @address-deleted="handleAddressDeletion"
  @address-updated="fetchAddresses"></Address>
                </div>
            </div>
            <div :class="getClass()" class="dv-create-preview">
                <div class="dv-front-face">
                    <canvas id="canvas-front" class="card-page" width="662" height="1040"></canvas>
                </div>
                <div class="dv-back-face">
                    <canvas id="canvas-back" class="card-page" width="662" height="1040"></canvas>
                </div>
            </div>
            <button class="logout-button" @click="logout">Logout</button>
            <button class="users-button" @click="manageusers">ManageUsers</button>
        </div>
    </div>
</template>

<script>
import { VueElement } from 'vue';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Popup from './Popup.vue';
import BuInsert from './BuInsert.vue';
import Address from './AddAddress.vue';

export default {
    name: 'CreateCard',
    components: {
        Cropper,
        Popup,
        BuInsert,
        Address
    },
    props: {
    },
    data() {
        return {
            apiKeyBk: "7ujwFRSG74kyurfV8nufbAg7",
            apiKey: "XBPhXp4bjbxP1aifSWEg5SfV",
            isExpired: false,
            nickname: '',
            name: '',
            surname: '',
            cardNo: '',
            affiliation: '',
            selectedImage: '',
            photo: null,
            message: 'arvbg.png',
            logobu: 'arvlogo.png',
            companyname: 'AI and Robotics Ventures Co., Ltd.',
            bggcolor: '#0544a8',
            companynamethai: 'บริษัท เอไอ แอนด์ โรโบติกส์ เวนเจอร์ส จํากัด',
            taxID: '0105561163590',
            img: '',
            isUploaded: false,
            isShow: false,
            isLoading: false,
            image: {
                src: null,
                type: null
            },
            coordinates: {
                width: 0,
                height: 0,
                left: 0,
                top: 0,
            },
            imgBu: 'arvbg.png',
            text: 'AI AND ROBOTICS VENTURES CO., LTD.',
            isPopupVisible: false,
            isInsertVisible: false,
            isAddressVisible: false,

            bu: '',
            buNames: [],
            working_type: '',
            selectedAddress: '',
            addresses: [],
            ADDRESS_THAI: '',
            ADDRESS_ENG: ''
        }
    },
    methods: {
        async fetchDataFromDatabase() {
            try {
                const response = await fetch(process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT + '/data/bu_name');
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                const data = await response.json();
                this.buNames = data;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        fetchData() {
            axios.get(process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT + `/api/data?buName=${this.bu}`)
                .then(response => {
                    const data = response.data;
                    this.message = `data:image/png;base64,${data.BU_BG}`
                    this.logobu = `data:image/png;base64,${data.Logo}`
                    this.companyname = data.COMPANY_NAMEENG;
                    this.companynamethai = data.COMPANY_NAMETHAI;
                    this.taxID = data.TAX_ID;
                    this.bggcolor = data.BG_COLOR;
                    this.text = data.COMPANY_NAMEENG;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },
        handleSelection() {
            this.fetchData();
        },
        async fetchAddresses() {
    try {
      const response = await fetch(
        process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT + '/api/addresses'
      );
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.json();
      this.addresses = data;
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  },
    handleAddressDeletion(deletedAddressId) {
      // ลบที่อยู่ที่ถูกลบออกจากอาร์เรย์ addresses
      this.addresses = this.addresses.filter(
        (address) => address.ID !== deletedAddressId
      );
      // ถ้าที่อยู่ที่ถูกลบเป็นที่อยู่ที่ถูกเลือก รีเซ็ตการเลือก
      if (this.selectedAddress === deletedAddressId) {
        this.selectedAddress = '';
      }
    },

        wrapText(context, text, x, y, lineHeight) {
            const lines = text.split('\n');
            lines.forEach(line => {
                context.fillText(line, x, y);
                y += lineHeight;
            });
            return lines.length;
        },

        trimEmptyLines(text) {
            return text.split('\n').filter(line => line.trim() !== '').join('\n');
        },

        async fetchAddressData() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT}/api/dataAddress?address=${this.selectedAddress}`);
                const data = response.data;

                // สมมติว่าข้อมูลที่อยู่จาก API ใช้ <br> แทนการเว้นบรรทัด ให้แปลงเป็น \n
                this.ADDRESS_THAI = this.trimEmptyLines(data.ADDRESS_THAI);
                this.ADDRESS_ENG = this.trimEmptyLines(data.ADDRESS_ENG);

                this.addTextToCanvas();
            } catch (error) {
                console.error('Error fetching addresses:', error);
            }
        },
        handleAddressSelection() {
            if (this.selectedAddress) {
                this.fetchAddressData();
            }
        },
        addTextToCanvas() {
            var ctx = document.getElementById("canvas-back").getContext("2d");

            ctx.font = "28px Prompt";
            ctx.fillStyle = "#000";
            this.wrapText(ctx, this.ADDRESS_THAI, 20); // ลด lineHeight จาก 30 เป็น 25

            this.wrapText(ctx, this.ADDRESS_ENG, 20); // ลด lineHeight จาก 30 เป็น 25
        },
        showPopup() {
            this.isPopupVisible = true;
        },
        showInsertPopup() {
            this.isInsertVisible = true;
        },
        showInsertAddressPopup() {
            this.isAddressVisible = true
        },
        crop() {
            const { canvas } = this.$refs.cropper.getResult();
            canvas.toBlob((blob) => {
            }, this.image.type);
        },
        reset() {
            this.image = {
                src: null,
                type: null
            }
        },
        async loadImage(event) {
            this.isLoading = true;

            const { files } = event.target;
            if (files && files[0]) {
                // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
                if (this.image.src) {
                    URL.revokeObjectURL(this.image.src)
                }
                var reader = new FileReader();
                // var imgtag = document.getElementById("img-test");
                const selectedFile = files[0]
                reader.onload = function (event) {
                    // imgtag.src = event.target.result;
                    this.img = event.target.result;
                    this.isUploaded = true;
                };

                reader.readAsDataURL(selectedFile);

                const formData = new FormData();
                formData.append('size', 'auto');
                formData.append('image_file', selectedFile, selectedFile.name);

                // Call remove bg api
                await axios({
                    method: 'post',
                    url: 'https://api.remove.bg/v1.0/removebg',
                    data: formData,
                    responseType: 'arraybuffer',
                    headers: {
                        'X-Api-Key': this.apiKey,
                    },
                    encoding: null
                })
                    .then((response) => {
                        if (response.status != 200) return console.error('Error:', response.status, response.statusText);
                        let buffer = response.data;
                        const blob = new Blob([buffer]);
                        // Create a Blob URL from the Blob
                        const blobURL = URL.createObjectURL(blob);
                        this.image = {
                            src: blobURL,
                        };
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        return console.error('Request failed:', error);
                    });
            }
            this.isUploaded = true;
        },
        OnCropChange({ coordinates, canvas }) {
            var imgtag = document.getElementById("img-test");
            imgtag.src = canvas.toDataURL();
            this.photo = canvas.toDataURL();
        },
        async initCards() {
            var logo = new Image();
            var bg = new Image();
            var back = new Image();

            logo.src = 'arvlogo.png';
            bg.src = 'arvbg.png';
            back.src = 'back.png';

            bg.onload = function () {

                var circle_canvas = document.getElementById("canvas-front");
                var context = circle_canvas.getContext("2d");
                context.fillStyle = "#fff";
                context.fillRect(0, 0, 350, 540);
                context.drawImage(bg, 0, 0, bg.width, bg.height, 0, 0, 662, 1040);
                // Logo
                // context.drawImage(logo, 0, 0, logo.width, logo.height, 15, 15, 112, 43);
                context.fillStyle = "#0544a8";
                context.fillRect(0, 960, 662, 80);

                context.fillStyle = "#FFF";
                context.lineStyle = "#ffff00";
                context.font = "24px Prompt";
                context.fillText("AI AND ROBOTICS VENTURES CO., LTD.", 100, 1010);

                var blobImage = document.getElementById("img-blob");
                blobImage.src = circle_canvas.toDataURL("image/png");
            }

            back.onload = function () {
                var ctxCv = document.getElementById("canvas-back");
                var ctx = ctxCv.getContext("2d");
                ctx.fillStyle = "#fff";
                ctx.fillRect(0, 0, 662, 1040);

                ctx.fillStyle = "#fff";
                ctx.letterSpacing = "0px";
                ctx.fillRect(0, 0, 350, 540);
                ctx.drawImage(logo, 0, 0, logo.width, logo.height, 15, 15, 224, 86);
                ctx.drawImage(back, 0, 0, back.width, back.height, 0, 760, 662, 280);
                ctx.font = "bold 28px Prompt";
                ctx.fillStyle = "#000";
                ctx.fillText("AI and Robotics Ventures Co., Ltd.", 20, 170);
                ctx.font = "28px Prompt";
                ctx.fillText("304 Vanit Place Aree (Building A)", 20, 220);
                ctx.fillText("25th Floor, Unit 2501, Phaholyothin Road,", 20, 260);
                ctx.fillText("Samsen Nai, Phaya Thai, Bangkok 10400", 20, 300);
                ctx.fillText("Tax ID: 0105561163590 Head Office Branch", 20, 340);
                ctx.font = "bold 28px Prompt";
                ctx.fillStyle = "#000";
                ctx.fillText("บริษัท เอไอ แอนด์ โรโบติกส์ เวนเจอร์ส จํากัด", 20, 430);
                ctx.font = "28px Prompt";
                ctx.fillText("304 อาคารวานิชเพลซ อารีย์ (อาคาร เอ)", 20, 480);
                ctx.fillText("ชั้น 25 ถนนพหลโยธิน", 20, 520);
                ctx.fillText("สามเสนใน, พญาไท, กรุงเทพมหานคร 10400", 20, 560);
                ctx.fillText("เลขประจำตัวผู้เสียภาษี: 0105561163590", 20, 600);
                ctx.fillText("สำนักงานใหญ่", 20, 640);
                ctx.letterSpacing = "0px";
                ctx.translate(0, 0);
                ctx.rotate(Math.PI / 2);
                ctx.fillText('ABCDEFGHHH', 50, -600);

                var blobImage = document.getElementById("img-blob-back");
                blobImage.src = ctxCv.toDataURL("image/png");
            }
        },
        async addTextToImage(imagePath, logoPath, bgPath, nickName, name, surname, affiliation, backPath, cardId, companyname, bggcolor, companynamethai, taxID, corpName, bu, ADDRESS_THAI, ADDRESS_ENG) {
            var circle_canvas = document.getElementById("canvas-front");
            var context = circle_canvas.getContext("2d");
            var img = new Image();
            var logo = new Image();
            var bg = new Image();
            img.src = imagePath;
            logo.src = logoPath;
            bg.src = bgPath;
            let maxLength = 0;
            let backgroundColor = this.working_type === 'Permanent' ? bggcolor : '#000000';
            var self = this;
            if (name.length > maxLength) {
                maxLength = name.length;
            }

            if (surname.length > maxLength) {
                maxLength = surname.length;
            }

            if (nickName.length > maxLength) {
                maxLength = nickName.length;
            }

            img.onload = function () {
                // White screen
                context.fillStyle = "#fff";
                context.fillRect(0, 0, 350, 540);
                // bg
                context.drawImage(bg, 0, 0, bg.width, bg.height, 0, 0, 662, 1040);
                // Person
                context.drawImage(img, 0, 0, img.width, img.height, 0, 0, 662, 1040);
                // Logo
                // context.drawImage(logo, 0, 0, logo.width, logo.height, 15, 15, 100, 43);
                context.lineWidth = 1;
                // 
                context.fillStyle = backgroundColor;
                console.log('bggcolor ', bggcolor)
                context.fillRect(0, 960, 662, 80);

                context.fillStyle = "#FFF";
                context.lineStyle = "#ffff00";
                context.font = "24px Prompt";
                var pos = 150;
                if (bu == "arv") {
                    pos = 100
                } else if (bu == "bind" || bu == "s1") {
                    pos = 190
                }
                var textWidth = context.measureText(affiliation).width;
                var canvasWidth = 662;
                var xPos = (canvasWidth - textWidth) / 2;
                context.fillText(affiliation, xPos, 1010);

                const grad = ctx.createLinearGradient(0, 0, 350, 0);
                grad.addColorStop(0, "rgba(255,255,255, 1)");  // 50% alpha
                grad.addColorStop(1, "rgba(255,255,255, 0.0)");
                context.fillStyle = grad;
                context.fillRect(0, 700, 350, 180);
                context.fillStyle = bggcolor;
                context.font = "bold 54px Prompt";
                context.fillText(nickName, 30, 770);
                context.fillStyle = "#fff";
                context.fillStyle = "#000";
                context.font = "bold 36px Prompt";
                context.fillText(name, 30, 830);
                context.fillStyle = "#fff";
                context.fillStyle = "#000";
                context.fillText(surname, 30, 870);

                var blobImage = document.getElementById("img-blob");
                blobImage.src = circle_canvas.toDataURL("image/png");
            };


            // Back card
            var ctxCv = document.getElementById("canvas-back");
            var ctx = ctxCv.getContext("2d");

            var back = new Image();
            back.src = backPath;
            back.onload = function () {
    ctx.letterSpacing = "0px";
    ctx.fillStyle = "#fff";
    ctx.fillRect(0, 0, 662, 1040);
    ctx.drawImage(logo, 0, 0, logo.width, logo.height, 10, 15, 320, 120);
    ctx.drawImage(back, 0, 0, back.width, back.height, 0, 780, 662, 260);

    
    ctx.font = "bold 28px Prompt";
    ctx.fillStyle = "#000";
    ctx.fillText(companyname, 20, 170);

   
    ctx.font = "28px Prompt";
    const addressEngLineCount = self.wrapText(ctx, ADDRESS_ENG, 20, 210, 45);

    
    const taxIDEngYPosition = 210 + addressEngLineCount * 45;
    ctx.fillText("Tax ID: " + taxID , 20, taxIDEngYPosition);
    ctx.fillText("Head Office Branch", 20, taxIDEngYPosition + 45);
  
    const companyNameThaiYPosition = taxIDEngYPosition + 120;
    ctx.font = "bold 28px Prompt";
    ctx.fillStyle = "#000";
    ctx.fillText(companynamethai, 20, companyNameThaiYPosition);

  
    ctx.font = "28px Prompt";
    const addressThaiYPosition = companyNameThaiYPosition + 40; 
    const addressThaiLineCount = self.wrapText(ctx, ADDRESS_THAI, 20, addressThaiYPosition, 45);

   
    const taxIDThaiYPosition = addressThaiYPosition + addressThaiLineCount * 45;
    ctx.fillText("เลขประจำตัวผู้เสียภาษี: " + taxID, 20, taxIDThaiYPosition); 
    ctx.fillText("สำนักงานใหญ่", 20, taxIDThaiYPosition + 45);
    // หมุนข้อความบัตร ID
    ctx.font = "bold 30px Prompt";
    ctx.letterSpacing = "10px";
    ctx.translate(0, 0);
    ctx.rotate(Math.PI / 2);
    ctx.fillText(cardId, 50, -600);

    // อัปเดตภาพบน canvas
    var blobImage = document.getElementById("img-blob-back");
    blobImage.src = ctxCv.toDataURL("image/png");
};

        },
        async createFrontCard() {

        },
        getClass() {
            if (!this.isShow) {
                return 'hide';
            } else {
                return 'show'
            }
        },
        async generate() {
            await this.addTextToImage(this.photo, this.logobu, this.message, this.nickname, this.name, this.surname, this.affiliation, "back.png", this.cardNo, this.companyname, this.bggcolor, this.companynamethai, this.taxID, this.text, this.bu, this.ADDRESS_THAI, this.ADDRESS_ENG);
            await this.addTextToImage(this.photo, this.logobu, this.message, this.nickname, this.name, this.surname, this.affiliation, "back.png", this.cardNo, this.companyname, this.bggcolor, this.companynamethai, this.taxID, this.text, this.bu, this.ADDRESS_THAI, this.ADDRESS_ENG);
            await this.addTextToImage(this.photo, this.logobu, this.message, this.nickname, this.name, this.surname, this.affiliation, "back.png", this.cardNo, this.companyname, this.bggcolor, this.companynamethai, this.taxID, this.text, this.bu, this.ADDRESS_THAI, this.ADDRESS_ENG);
            await this.addTextToImage(this.photo, this.logobu, this.message, this.nickname, this.name, this.surname, this.affiliation, "back.png", this.cardNo, this.companyname, this.bggcolor, this.companynamethai, this.taxID, this.text, this.bu, this.ADDRESS_THAI, this.ADDRESS_ENG);
            this.isShow = true;
        },
        downloadFront() {
            var canvas = document.getElementById("canvas-front");
            var dataURL = canvas.toDataURL("image/png");
            saveAs(dataURL, this.name + '_' + this.surname + '_front.png');
        },
        downloadBack() {
            var canvas = document.getElementById("canvas-back");
            var dataURL = canvas.toDataURL("image/png");
            saveAs(dataURL, this.name + '_' + this.surname + '_back.png');
        },
        close() {
            this.isShow = false;
        },
        logout() {
            localStorage.removeItem('loggedIn');
            console.log("User logged out");
            window.location.href = '/';

        },
        manageusers() {

            window.location.href = '/manage-users';

        },
    },

    computed: {},
    updated() { },
    async mounted() {
        await this.initCards();
        this.fetchDataFromDatabase();
        this.fetchAddresses();
    },
    async created() {
    }
}


</script>

<style scoped>
@font-face {
    font-family: Prompt;
    src: url(prompt.ttf);
}

@font-face {
    font-family: Roboto;
    src: url(roboto.ttf);

}

.logout-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    border-radius: 7px;
    color: white;
    padding: 10px 20px;
    background-color: rgb(161, 18, 18);
    cursor: pointer;
    z-index: 900;
    border: none;
}

.users-button {
    position: absolute;
    bottom: 20px;
    right: 125px;
    border-radius: 7px;
    color: white;
    padding: 10px 20px;
    background-color: rgb(4, 129, 25);
    cursor: pointer;
    z-index: 900;
    border: none;
}

.main {
    position: relative;
    width: 100%;
    height: 100%;
    font-family: Prompt;
}

.dv-back-face {
    position: absolute;
    left: 1100px;
    top: 120px;
    display: none;
}

.dv-front-face {
    position: absolute;
    left: 10%;
    top: 20px;
    display: none;
}

.card-page {
    border-radius: 15px;
}

table {
    color: white;
}

.textbox {
    height: 25px;
    width: 250px;
}

.select {
    height: 30px;
    width: 258px;
}

td {
    padding-left: 30px;
    padding-right: 30px;
}

.button {
    position: absolute;
    left: 3%;
    top: 89%;
    border-radius: 15px;
    color: white;
    padding: 15px 20px;
    background-color: rgb(44, 44, 193);
    width: 23.7%;
    text-align: center;
    cursor: pointer;
}

.btn-step-1 {
    border-radius: 7px;
    font-size: 18px;
    color: white;
    padding: 15px 20px;
    background-color: rgb(44, 44, 193);
    width: 100%;
    text-align: center;
    border: none;
}

.btn-pop {
    border-radius: 7px;
    font-size: 18px;
    color: white;
    padding: 15px 20px;
    background-color: rgb(44, 44, 193);
    width: 100%;
    text-align: center;
    border: none;
    width: 320px;
}

.button-download-bk {
    position: absolute;
    border-radius: 15px;
    color: white;
    padding: 15px 20px;
    background-color: rgb(44, 44, 193);
    width: 200px;
    text-align: center;
    left: 1150px;
    top: 575px;
    cursor: pointer;
    display: none;
}

.button-download-fn {
    position: absolute;
    border-radius: 15px;
    color: white;
    padding: 15px 20px;
    background-color: rgb(44, 44, 193);
    width: 200px;
    text-align: center;
    left: 750px;
    top: 575px;
    cursor: pointer;
    display: none;
}

.img-before {
    position: absolute;
    top: 35px;
    width: 27%;
    left: 70%;
    height: 512px;
    border-radius: 15px;
    z-index: 11;
    color: black;
}

.img-bu {
    position: absolute;
    top: 45px;
    height: 32px;
    left: 71%;
    z-index: 13;
}

.mock-bottom {
    position: absolute;
    top: 507px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    left: 70%;
    width: 27%;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 12;
    padding-top: 10px;
    color: rgb(212, 212, 212);
}

.info-area {
    position: absolute;
    top: 410px;
    left: 9%;
    text-align: left;
    color: rgb(168, 168, 168);
}

.info-generate {
    position: absolute;
    top: 556px;
    left: 51%;
    text-align: left;
    color: rgb(119, 143, 177);
    z-index: 50;
}

.label-preview {
    position: absolute;
    top: 10px;
    left: 82%;
    color: white;

}

.label-adjust {
    position: absolute;
    top: 10px;
    left: 48%;
    color: white;
}

.img-after {
    position: absolute;
    top: 380px;
    height: 230px;
    left: 250px;
}

.pop-up {
    position: absolute;
    top: 500px;
    height: 230px;
    left: 40px;
}

.pop-up-add {
    position: absolute;
    top: 470px;
    height: 230px;
    left: 40px;
}

.pop-up-add-address {
    position: absolute;
    top: 530px;
    height: 230px;
    left: 40px;
}

.btn-upload {
    height: 40px;
    width: 258px;
}

.cropper {
    position: absolute;
    width: 40%;
    left: 30%;
    height: 100%;
}

td {
    padding: 0;
    text-align: right;
    padding-right: 10px;
}

.table-label {
    color: white;
}

.table-label-1 {
    color: rgb(0, 0, 0);
}

.dv-form-data {
    position: absolute;
    left: 10px;
    top: 90px;
}

.modal-content {
    z-index: 2;
    width: 1200px;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: black;
    border-radius: 10px;
}

.arv-logo {
    width: 220px;
    height: 85px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.img-bg {
    position: absolute;
    z-index: 10;
    top: 35px;
    height: 512px;
    width: 27%;
    left: 70%;
    border-radius: 15px;
}

.img-loading {
    /* width: 64px; */
    height: 64px;
    position: absolute;
    left: 47%;
    top: 270px;
    color: white;
}

.img-loading-preview {
    /* width: 64px; */
    height: 64px;
    position: absolute;
    left: 81%;
    top: 270px;
    color: white;
}

.dv-create-preview {
    position: absolute;
    height: 100%;
    left: 33%;
    width: 67%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 30;
    background-color: rgb(30, 30, 30);
}

.img-blob {
    position: absolute;
    z-index: 40;
    width: 331px;
    height: 520px;
    left: 35%;
    top: 30px;
    border-radius: 15px;
}

.img-blob-back {
    position: absolute;
    z-index: 40;
    width: 331px;
    height: 520px;
    left: 65%;
    top: 30px;
    border-radius: 15px;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.close {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 30px;
    top: 20px;
    z-index: 50;
}

.resized-image {
    width: 300px;
    height: auto;
}

.popup-link {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: rgb(41, 126, 229);
    position: absolute;
    top: 0px;
    left: 9%;
    text-align: left;
    width: 150px;
    margin-left: 70px;
}

.popup-link-add {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: rgb(218, 174, 0);
    position: absolute;
    top: 0px;
    left: 9%;
    text-align: left;
    width: 190px;
    margin-left: 70px;
}

.popup-link-address {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: rgb(0, 140, 30);
    position: absolute;
    top: 0px;
    left: 9%;
    text-align: left;
    width: 190px;
    margin-left: 70px;
}
</style>
